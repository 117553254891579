<div class="pt-7">
  <div class="p-3">
    <app-back-arrow></app-back-arrow>
  </div>
  <div class="p-3">
    <div class="pb-2">
      <p class="fs-16 fw-400 black-color">
        Your plan will be canceled but remains active until the end of your
        billing period on [expire by time].
      </p>
      <p class="fs-16 fw-400 black-color">
        If you change your mind, you can renew your subscription. And if you
        want to upgrade the plan, you can upgrade instead of cancelling.
      </p>
      <p class="fs-16 fw-400 black-color">
        By canceling your plan, you agree to Scremer’s Terms of Service and
        Privacy Policy.
      </p>
    </div>
    <div class="pt-3 d-flex justify-content-end">
      <button class="btn btn-primary me-2 background-color">Go Back</button>
      <button
        mat-raised-button
        class="btn-primary br-color rounded-pill active-link lato-font-family fw-600 fs-16"
        (click)="confirmCancel()"
      >
        Cancel Plan
      </button>
    </div>
  </div>
</div>
