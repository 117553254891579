<div class="d-flex align-items-center py-3">
  <div>
    <mat-icon class="material-icons-outlined mat-icon-height me-3"
      >history</mat-icon
    >
  </div>
  <div *ngIf="search?.userName === 'null'" class="fw-400 fs-16">
    <p class="m-0 truncate-ellipsis">{{ search?.fullName }}</p>
  </div>
  <div *ngIf="search?.userName !== 'null'">
    <div class="d-flex align-items-center">
      <img
        class="profile-image-40 rounded-object profile-border {{
          search?.isVerified === 'brand' ? 'object-contain' : ''
        }}"
        [src]="
          search?.isVerified === 'user'
            ? search?.profile
              ? blobStorageLink + search?.profile
              : defaultImage
            : search?.isVerified === 'brand'
            ? search?.profile
              ? blobStorageLink + search?.profile
              : businessDefaultImage
            : null
        "
        alt="profile"
        mat-card-avatar
      />
      <div class="ps-2 d-flex flex-column">
        <div class="d-flex">
          <p
            *ngIf="search?.isVerified === 'user'"
            class="fw-600 fs-16 m-0 truncate-ellipsis"
          >
            {{ search?.fullName }}
          </p>
          <p
            *ngIf="search?.isVerified === 'brand'"
            class="fw-600 fs-16 m-0 truncate-ellipsis"
          >
            {{ search?.userName }}
          </p>
          <span
            class="material-icons active-link icon-height fs-16"
            [ngClass]="search.subscription"
            *ngIf="search?.isUser === 'VERIFIED'"
          >
            verified
          </span>
        </div>
        <p
          *ngIf="search?.isVerified === 'user'"
          class="fw-400 fs-12 m-0 truncate-ellipsis scream-icon-color"
        >
          {{ search?.userName }}
        </p>
        <p
          *ngIf="search?.isVerified === 'brand'"
          class="fw-400 fs-12 m-0 truncate-ellipsis scream-icon-color"
        >
          {{ search?.fullName }}
        </p>
      </div>
    </div>
  </div>
</div>
