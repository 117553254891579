import { StorageBlobService } from './../../core/service/storageBlobService/storage-blob.service';
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '../../core/service/header/header.service';
import { ResponseService } from '../../core/service/responseService/response.service';
import { UploadImageComponent } from '../../shared/upload-image/upload-image.component';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-response',
  templateUrl: './create-response.component.html',
  styleUrls: ['./create-response.component.scss'],
})
export class CreateResponseComponent implements AfterContentInit, OnInit {
  @Input() suid!: any;
  @Input() responseField!: boolean;
  @Output() refreshScreamEvt = new EventEmitter<any>();
  @ViewChild(UploadImageComponent) uploadImages!: UploadImageComponent;
  @ViewChild('responseTextarea') responseTextarea!: ElementRef;
  noOfUploadImages = 3;
  responseText!: string;
  errorMessage!: string;
  showLoader!: boolean;
  userProfileImage: any;
  responseImages: any;
  isTooltipVisible: boolean = false;
  imageKey: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  businessProfile =
    JSON.parse(this.dataTransferService.getBrandDetails() || null) || '';
  isBusinessSubscribed: boolean = true;

  private readonly MAX_FILE_SIZE = 10000000;
  platformId: Object;

  constructor(
    private readonly headerService: HeaderService,
    private responseService: ResponseService,
    private storageBlobService: StorageBlobService,
    private snackbarService: SnackbarService,
    private readonly dataTransferService: DataTransferService,
    @Inject(PLATFORM_ID) platformId: Object,
    public router: Router
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
    this.snackbarService.showTooltip$?.subscribe((value) => {
      this.isTooltipVisible = value;
    });

    this.snackbarService.focusTextarea$?.subscribe(() => {
      this.responseTextarea.nativeElement.focus();
    });

    !this.businessProfile.subscription ||
    this.businessProfile.subscription === 'FREE'
      ? (this.isBusinessSubscribed = false)
      : null;
  }

  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.screen.width < 577) {
        setTimeout(() => {
          this.headerService.hide();
        }, 0);
      }
    }
  }

  isValid(): boolean {
    return (
      this.responseText?.trim().length > 0 || this.responseImages?.length > 0
    );
  }

  reset() {
    this.responseText = '';
    this.uploadImages.resetAddedImages();
    this.imageKey = '';
    this.responseImages = [];
  }

  createResponseBody() {
    return {
      response: this.responseText?.trim(),
      images: this.imageKey,
    };
  }

  uploadImageToResponse() {
    if (this.isBusinessSubscribed) {
      this.closeTooltip();
      this.uploadImages.getAddImg().nativeElement.click();
    }
  }

  onUploadImage(event: any) {
    this.responseImages = event;
  }

  submitResponse() {
    if (!this.isBusinessSubscribed) {
      return;
    }
    if (this.responseImages && this.responseImages.length > 0) {
      this.showLoader = true;
      const formData = new FormData();
      let totalFileSize = 0;
      this.responseImages.forEach((file: any) => {
        formData.append('file', file.imageFile);
        totalFileSize += file.imageFile.size;
      });
      if (this.responseImages && totalFileSize < this.MAX_FILE_SIZE) {
        this.storageBlobService.uploadImage(formData).subscribe({
          next: (data: any) => {
            this.showLoader = false;
            this.imageKey = data.body.responseBody.join(', ');
            this.response();
          },
          error: () => {
            this.showLoader = false;
            this.snackbarService.openSnackBar(
              'Internal server error. Please try again!',
              'error-snackbar'
            );
          },
        });
      } else {
        this.snackbarService.openSnackBar(
          'Your file exceeds maximum size of 10MB.',
          'error-snackbar'
        );
      }
    } else {
      this.response();
    }
  }

  response() {
    if (this.isValid()) {
      this.showLoader = true;
      this.responseService
        ?.postResponse(this.createResponseBody(), this.suid)
        .subscribe({
          next: (data: any) => {
            if (data.body.responseCode == 201) {
              this.showLoader = false;
              this.refreshScreamEvt.emit();
              this.reset();
            }
          },

          error: (error) => {
            if (error.error.responseCode === 403) {
              this.isBusinessSubscribed = false;
              this.businessProfile.subscription = 'FREE';
              localStorage.setItem(
                'BrandDetails',
                JSON.stringify(this.businessProfile)
              );
            }
            this.errorMessage = error?.error?.responseMessage;
            this.showLoader = false;
          },
        });
    } else {
      this.reset();
    }
  }

  setProfileImage() {
    if (this.dataTransferService.isLoggedin()) {
      return this.userProfileImage
        ? this.blobStorageLink + this.userProfileImage
        : this.defaultImage;
    } else if (this.dataTransferService.isBusinessLoggedin()) {
      return this.businessProfile.logoImage
        ? this.blobStorageLink + this.businessProfile?.logoImage
        : this.businessDefaultImage;
    }
    return this.defaultImage;
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  closeTooltip() {
    this.isTooltipVisible = false;
  }
}
