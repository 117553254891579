import { isPlatformBrowser } from '@angular/common';
import { SliderService } from './../../core/service/sliderService/slider.service';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';

@Component({
  selector: 'app-hate-rate-slider',
  templateUrl: './hate-rate-slider.component.html',
  styleUrls: ['./hate-rate-slider.component.scss'],
})
export class HateRateSliderComponent implements OnInit {
  constructor(
    private sliderService: SliderService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('touchstart', () => {}, { passive: true });
    }
  }

  @Output() sliderValue = new EventEmitter<any>();
  @Input() slider: any;
  max = 199;
  min = 0;
  value = 0;
  emotionIndex = 0;
  feelings = [
    'frustrated',
    'mad',
    'furious',
    'angry',
    'disappointed',
    'satisfied',
    'good',
    'happy',
    'joyful',
    'delighted',
  ];
  reset() {
    this.value = 0;
    this.emotionIndex = 0;
  }
  ngOnInit(): void {
    this.value = this.slider ? this.slider : this.value;
    this.slider ? this.getState(this.value) : null;
  }

  getState(value: any) {
    this.sliderValue.emit(value);
    this.emotionIndex = this.sliderService.getSliderValue(value);
  }
}
