<div class="container px-4 py-4">
  <span
    class="material-symbols-outlined pointer mb-3"
    (click)="goBackToPreviousPage()"
    >arrow_back</span
  >
  <div>
    <h1 class="fw-700 fs-20">Contact Us</h1>
    <p>
      If you have any questions, please contact our Support team through
      <a href="mailto:scream@scremer.com"> scream@scremer.com</a>
    </p>
    <h2 class="fw-700 fs-16">Registered Office Address</h2>
    <p class="m-0">Better Analytics Private Limited</p>
    <p>
      Flat No 201 2nd Floor H.No 12-12AB11-201, Housing Board Singapore Class
      Township, HYDERABAD, Rangareddi, Telangana, India, 500088.
    </p>
  </div>
</div>
