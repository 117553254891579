import { MatSlider } from '@angular/material/slider';
import { SliderService } from './../../core/service/sliderService/slider.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-hate-rate-slider',
  templateUrl: './hate-rate-slider.component.html',
  styleUrls: ['./hate-rate-slider.component.scss'],
})
export class HateRateSliderComponent implements OnInit {
  constructor(private sliderService: SliderService) {}
  @Output() sliderValue = new EventEmitter<any>();
  @Input() slider: any;
  @ViewChild('slider', { static: false }) slideer!: MatSlider;

  max = 199;
  min = 0;
  value = 0;
  emotionIndex = 0;
  feelings = [
    'frustrated',
    'mad',
    'furious',
    'angry',
    'disappointed',
    'satisfied',
    'good',
    'happy',
    'joyful',
    'delighted',
  ];
  reset() {
    this.value = 0;
    this.emotionIndex = 0;
  }
  ngOnInit(): void {
    this.value = this.slider ? this.slider : this.value;
    this.slider ? this.getState(this.value) : null;
  }
  ngAfterViewInit() {
    if (this.slideer?._elementRef?.nativeElement) {
      this.slideer._elementRef.nativeElement.addEventListener(
        'click',
        this.onTrackClick.bind(this)
      );
      let thuch = this.slideer._elementRef.nativeElement.addEventListener(
        'touchstart',
        this.onTrackClick.bind(this)
      );
      console.log(thuch, 'test value');
    } else {
      console.log('Slider element not found');
    }
  }
  getState(value: any) {
    this.sliderValue.emit(value);
    this.emotionIndex = this.sliderService.getSliderValue(value);
  }

  onTrackClick(event: MouseEvent | TouchEvent) {
    const sliderElement = this.slider?._elementRef?.nativeElement;
    console.log(sliderElement);

    if (!sliderElement) {
      console.error('Slider element not found');
      return;
    }

    const trackRect = sliderElement.getBoundingClientRect();
    if (!trackRect) {
      console.error('Unable to get slider track bounding box');
      return;
    }

    let xPosition;
    if (event instanceof MouseEvent) {
      xPosition = event.clientX;
    } else if (event instanceof TouchEvent) {
      xPosition = event.touches[0].clientX;
    }

    if (xPosition === undefined) {
      console.error('Invalid touch or click event');
      return;
    }

    const buffer = 50;
    const isWithinBounds =
      xPosition >= trackRect.left - buffer &&
      xPosition <= trackRect.right + buffer;

    if (isWithinBounds) {
      const clickPositionX = xPosition - trackRect.left;
      const trackWidth = trackRect.width;
      console.log(trackRect.width, 'track rect widfth');

      const newValue =
        this.min + (clickPositionX / trackWidth) * (this.max - this.min);
      console.log('Track clicked at position X:', clickPositionX);
      console.log('New slider value:', newValue);

      this.value = Math.round(newValue);
      const ntf = this.getState(this.value);
      console.log(ntf, 'new value');
    } else {
      console.log('Click outside slider bounds');
    }
  }
}
