import { FollowersFollowing } from './../../core/interfaces/followers-following';
import { TokenService } from './../../core/service/tokenService/token.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from './../../core/service/userService/user.service';
import { catchError, map, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LogoutComponent } from '../logout/logout.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BrandService } from './../../core/service/brandService/brand.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { LoginResponse } from './../../core/interfaces/login-response';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss'],
})
export class FollowersComponent implements OnInit {
  content: any;
  errorMessage: any;
  followerShimmer = true;
  myAndUserFollowers: FollowersFollowing[] = [];
  myAndUserFollowing: FollowersFollowing[] = [];
  brandFollowers: LoginResponse[] = [];
  pageIndex = 0;
  pageSize = 15;
  pageIndexFollowers: number = 0;
  pageIndexFollowing: number = 0;
  profile: any;
  ownUser: boolean = false;
  error: any;
  selectedTab: any;
  followerUuids: any;
  filteredUser: any;
  filteredUserName: any;
  userFollowerCount: any;
  userFollowingCount: any;
  myFollowerCount: any;
  myFollowingCount: any;
  userName: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  constructor(
    private tokenService: TokenService,
    private userService: UserService,
    public readonly route: ActivatedRoute,
    private readonly router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FollowersComponent>,
    private brandService: BrandService,
    private readonly dataTransferService: DataTransferService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedTab = data.selectedTab;
  }

  ngOnInit(): void {
    if (this.data.user == null && !this.data.brand) {
      this.getMyFollowers();
      this.getMyFollowing();
    } else if (this.data.brand) {
      this.getBrandFollowers();
    } else {
      this.getUserFollowing();
      this.getUserFollowers();
    }

    this.userName = this.tokenService.getUserName();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  navigateToUserProfile(userName: string) {
    this.router.navigate([`/${userName}`]);
    this.dialogRef.close();
  }
  navigateToBrandProfile(brandName: string) {
    this.router.navigate([`/brand/${brandName}`]);
    this.dialogRef.close();
  }
  onScrollFollowing(): void {
    if (this.data.user == null) {
      this.onScrollMyFollowing();
    } else {
      this.onScrollUserFollowing();
    }
  }
  onScrollFollowers(): void {
    if (this.data.user == null && !this.data.brand) {
      this.onScrollMyFollower();
    } else if (this.data.brand) {
      this.onScrollBrandFollower();
    } else {
      this.onScrollUserFollower();
    }
  }

  fetchMyFollowers(pageIndex: number, pageSize: number) {
    return this.userService.fetchingMyFollowers({ pageIndex, pageSize })?.pipe(
      map((data: any) => data.body.responseBody),
      catchError((error) => {
        this.errorMessage = error.error.responseMessage;
        return of(this.errorMessage);
      })
    );
  }

  getMyFollowers(): void {
    this.fetchMyFollowers(this.pageIndex, this.pageSize)?.subscribe(
      (followers) => {
        this.myFollowerCount = followers.totalItems;
        this.myAndUserFollowers = followers.userFollowDTO;
        this.followerShimmer = false;
      }
    );
  }

  onScrollMyFollower() {
    if (this.myAndUserFollowers.length < this.myFollowerCount) {
      this.followerShimmer = true;
      this.fetchMyFollowers(
        ++this.pageIndexFollowers,
        this.pageSize
      )?.subscribe((followers) => {
        this.myAndUserFollowers?.push(...followers.userFollowDTO);
        this.followerShimmer = false;
      });
    }
  }
  fetchUserFollowers(pageIndex: number, pageSize: number) {
    return this.userService
      .fetchingUserFollowers(this.data.user, { pageIndex, pageSize })
      ?.pipe(
        map((data: any) => data.body.responseBody),
        catchError((error) => {
          this.errorMessage = error.error.responseMessage;
          return of(this.errorMessage);
        })
      );
  }
  getUserFollowers(): void {
    this.profile = JSON.parse(this.dataTransferService.getUserDetails() || '');
    this.fetchUserFollowers(this.pageIndex, this.pageSize)?.subscribe(
      (followers) => {
        this.userFollowerCount = followers.totalItems;
        this.myAndUserFollowers = followers.userFollowDTO;
        this.followerShimmer = false;
        const filteredUserIndex = this.myAndUserFollowers?.findIndex(
          (user: any) =>
            user.loginResponseDTO?.userName === this.profile.userName
        );
        if (filteredUserIndex !== -1) {
          this.filteredUser = this.myAndUserFollowers?.splice(
            filteredUserIndex,
            1
          )[0];

          this.myAndUserFollowers?.unshift(this.filteredUser);
        }
      }
    );
  }
  onScrollUserFollower() {
    if (this.myAndUserFollowers.length < this.userFollowerCount) {
      this.followerShimmer = true;
      this.fetchUserFollowers(
        ++this.pageIndexFollowers,
        this.pageSize
      )?.subscribe((followers) => {
        this.followerShimmer = false;
        this.myAndUserFollowers?.push(...followers.userFollowDTO);
      });
    }
  }
  fetchUserFollowing(pageIndex: number, pageSize: number) {
    return this.userService
      .fetchingUserFollowing(this.data.user, { pageIndex, pageSize })
      ?.pipe(
        map((data: any) => data.body.responseBody),
        catchError((error) => {
          this.errorMessage = error.error.responseMessage;
          return of(this.errorMessage);
        })
      );
  }
  getUserFollowing(): void {
    this.fetchUserFollowing(this.pageIndex, this.pageSize)?.subscribe(
      (following) => {
        this.userFollowingCount = following.totalItems;
        this.myAndUserFollowing = following.userFollowDTO;
        this.followerShimmer = false;
        const filteredUserIndex = this.myAndUserFollowing?.findIndex(
          (user: any) =>
            user.loginResponseDTO?.userName === this.profile.userName
        );
        if (filteredUserIndex !== -1) {
          this.filteredUserName = this.myAndUserFollowing?.splice(
            filteredUserIndex,
            1
          )[0];

          this.myAndUserFollowing?.unshift(this.filteredUserName);
        }
      }
    );
  }
  onScrollUserFollowing() {
    if (this.myAndUserFollowing.length < this.userFollowingCount) {
      this.followerShimmer = true;
      this.fetchUserFollowing(
        ++this.pageIndexFollowing,
        this.pageSize
      )?.subscribe((following) => {
        this.followerShimmer = false;
        this.myAndUserFollowing?.push(...following.userFollowDTO);
      });
    }
  }

  isBrandVerified(brandDTO: any): boolean {
    return brandDTO && brandDTO.documentVerificationStatus === 'VERIFIED';
  }

  fetchMyFollowing(pageIndex: number, pageSize: number) {
    return this.userService.fetchingMyFollowing({ pageIndex, pageSize })?.pipe(
      map((data: any) => {
        if (data && data.body && data.body.responseBody) {
          const responseBody = data.body.responseBody;
          const documentVerificationStatusList: any[] = [];
          if (responseBody.userFollowDTO) {
            responseBody.userFollowDTO.forEach(
              (userFollow: {
                brandDTO: { documentVerificationStatus: any };
              }) => {
                if (userFollow.brandDTO) {
                  const documentVerificationStatus =
                    userFollow.brandDTO.documentVerificationStatus;
                  if (documentVerificationStatus) {
                    documentVerificationStatusList.push(
                      documentVerificationStatus
                    );
                  }
                }
              }
            );
          }
          return data.body.responseBody;
        }
      }),
      catchError((error) => {
        this.errorMessage = error.error.responseMessage;
        return of(this.errorMessage);
      })
    );
  }

  getMyFollowing(): void {
    this.fetchMyFollowing(this.pageIndex, this.pageSize)?.subscribe(
      (following) => {
        this.myFollowingCount = following.totalItems;

        this.myAndUserFollowing = following.userFollowDTO;
        this.followerShimmer = false;
      }
    );
  }
  onScrollMyFollowing() {
    if (this.myAndUserFollowing.length < this.myFollowingCount) {
      this.followerShimmer = true;
      this.fetchMyFollowing(++this.pageIndexFollowing, this.pageSize).subscribe(
        (following) => {
          this.myAndUserFollowing?.push(...following.userFollowDTO);
          this.followerShimmer = false;
        }
      );
    }
  }
  follow(user: any) {
    if (user.following) {
      const dialogRef = this.dialog.open(LogoutComponent, {
        width: '300px',
        panelClass: ['border-radius'],
        autoFocus: false,
      });
      this.tokenService.setContent(true);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'unfollow') {
          user.following = false;
          this.userService
            .followOrUnfollowUser(user.uuid)
            .then((data: any) => {
              if (data.responseCode === 201) {
                if (this.data.user == null) {
                  this.getMyFollowing();
                  this.getMyFollowers();
                  this.myFollowingCount--;
                  this.dataTransferService.updateFollowingCount(
                    this.myFollowingCount
                  );
                }
              }
            })
            .catch((error) => {
              this.error = error.error?.responseMessage;
              user.following = true;
            });
        }
      });
    } else {
      user.following = true;
      this.userService
        .followOrUnfollowUser(user.uuid)
        .then((data: any) => {
          if (data.responseCode === 200) {
            if (this.data.user == null) {
              this.getMyFollowing();
              this.getMyFollowers();
              this.myFollowingCount++;
              this.dataTransferService.updateFollowingCount(
                this.myFollowingCount
              );
            }
          }
        })
        .catch((error) => {
          this.error = error.error?.responseMessage;
          user.following = false;
        });
    }
  }
  followBrand(brand: any) {
    if (brand.following) {
      const dialogRef = this.dialog.open(LogoutComponent, {
        width: '300px',
        panelClass: ['border-radius'],
        autoFocus: false,
      });
      this.tokenService.setContent(true);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'unfollow') {
          brand.following = false;
          this.brandService
            .followOrUnfollowBrand(brand.ubid)
            .then((data: any) => {
              if (data.responseCode === 201) {
                if (this.data.user == null) {
                  this.getMyFollowing();
                  this.getMyFollowers();
                  this.myFollowingCount--;
                  this.dataTransferService.updateFollowingCount(
                    this.myFollowingCount
                  );
                }
              }
            })
            .catch((error: any) => {
              this.error = error.error?.responseMessage;
              brand.following = true;
            });
        }
      });
    } else {
      brand.following = true;
      this.brandService
        .followOrUnfollowBrand(brand.ubid)
        .then((data: any) => {
          if (data.responseCode === 200) {
            if (this.data.user == null) {
              this.getMyFollowing();
              this.getMyFollowers();
              this.dataTransferService.updateFollowingCount(
                this.myFollowingCount
              );
            }
          }
        })
        .catch((error: any) => {
          this.error = error.error?.responseMessage;
          brand.following = false;
        });
    }
  }
  fetchBrandFollowers(pageIndex: number, pageSize: number) {
    return this.brandService
      .fetchingBrandFollowers(this.data.brandName, { pageIndex, pageSize })
      ?.pipe(
        map((data: any) => data.body.responseBody),
        catchError((error) => {
          this.errorMessage = error.error.responseMessage;
          return of(this.errorMessage);
        })
      );
  }
  getBrandFollowers(): void {
    this.profile = JSON.parse(this.dataTransferService.getUserDetails() || '');
    this.fetchBrandFollowers(this.pageIndex, this.pageSize).subscribe(
      (followers) => {
        this.userFollowerCount = followers.totalItems;
        this.brandFollowers = followers.loginResponseDTOList;
        this.followerShimmer = false;
        const filteredUserIndex = this.brandFollowers?.findIndex(
          (user: any) => user?.userName === this.profile.userName
        );
        if (filteredUserIndex !== -1) {
          this.filteredUser = this.brandFollowers?.splice(
            filteredUserIndex,
            1
          )[0];

          this.brandFollowers?.unshift(this.filteredUser);
        }
      }
    );
  }
  onScrollBrandFollower() {
    if (this.brandFollowers.length < this.userFollowerCount) {
      this.followerShimmer = true;
      this.fetchBrandFollowers(
        ++this.pageIndexFollowers,
        this.pageSize
      )?.subscribe((followers) => {
        this.followerShimmer = false;
        this.brandFollowers?.push(...followers.loginResponseDTOList);
      });
    }
  }
}
