import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OtpTimerService } from 'src/app/core/service/otpTimer/otp-timer.service';
import { OtpTransferComponent } from 'src/app/user/otp-transfer/otp-transfer.component';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss'],
})
export class CancelPlanComponent {
  constructor(
    public dialog: MatDialog,
    private otpTimerService: OtpTimerService
  ) {}

  confirmCancel() {
    this.otpTimerService.resetTimer();
    this.otpTimerService.startTimer();
    this.dialog.open(OtpTransferComponent, {
      maxWidth: '464px',
      height: '328px',
      panelClass: ['cancel-plan-dialog'],
    });
  }
}
