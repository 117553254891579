import { FaqService } from './../../../core/service/faqService/faq.service';
import { TokenService } from './../../../core/service/tokenService/token.service';
import { OtpTimerService } from './../../../core/service/otpTimer/otp-timer.service';
import { OtpTransferComponent } from './../../../user/otp-transfer/otp-transfer.component';
import { OtpService } from './../../../core/service/otpService/otp.service';
import { UserService } from '../../../core/service/userService/user.service';
import {
  Component,
  OnInit,
  AfterContentInit,
  PLATFORM_ID,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BottomNavBarService } from '../../../core/service/bottomNavBar/bottom-nav-bar.service';
import { HeaderService } from '../../../core/service/header/header.service';
import { LoaderService } from '../../../core/service/loader/loader.service';
import { SnackbarService } from '../../../core/service/snackBar/snackbar.service';
import { StorageBlobService } from '../../../core/service/storageBlobService/storage-blob.service';
import { NgxImageCompressService, DOC_ORIENTATION } from 'ngx-image-compress';
import { DataTransferService } from '../../../core/service/data-transfer/data-transfer.service';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Name } from '../../search/search.component';
import { Observable, catchError, map, of } from 'rxjs';
import * as moment from 'moment';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-user-details',
  templateUrl: './edit-user-details.component.html',
  styleUrls: ['./edit-user-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],
})
export class EditUserDetailsComponent implements OnInit, AfterContentInit {
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  userDetails: any = JSON.parse(this.dataTransferService.getUserDetails());
  minDate: Date = new Date();
  maxDate: Date = new Date();
  errorMessage!: any;
  editProfile!: FormGroup;
  imageKey: any;
  imageUrl: any;
  isUserNameUnique!: boolean;
  userNameLoader = false;
  verifyUserNameResponseMessage: any;
  orientation!: DOC_ORIENTATION;
  profileImage: any;
  formEdited: boolean = false;
  platformId: Object;
  isImageUpdated!: boolean;
  formData!: FormData;
  uploadedImage!: string;
  selectedImage!: string;
  currentuser: any;
  existingUserName: any;
  profile: any;
  URL: any;
  verifyOtpResponseMessage: any;
  completeProfile: any;
  momentDate: any;
  formattedDate: any;

  constructor(
    private userService: UserService,
    private readonly bottomService: BottomNavBarService,
    public readonly headerService: HeaderService,
    public loaderService: LoaderService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private storageBlobService: StorageBlobService,
    public imageCompress: NgxImageCompressService,
    public dataTransferService: DataTransferService,
    private otpService: OtpService,
    public otpTimerService: OtpTimerService,
    private tokenService: TokenService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.platformId = platformId;
  }

  ngOnInit(): void {
    this.isCompleteProfile();
    this.dateValidation();
    this.tokenService.isFormValid$.subscribe((state) => {
      const mobileNumberControl = this.editProfile?.get('mobileNumber');
      if (this.isCompleteProfile()) {
        mobileNumberControl?.disable();
      } else {
        mobileNumberControl?.enable();
      }
      this.fetchUserDetails();
    });
    this.editProfile = this.editProfileForm();
    this.fetchUserDetails();
  }
  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.hideHeader();
    }
    setTimeout(() => {
      this.bottomService.show();
    }, 0);
  }
  hideHeader() {
    if (window.screen.width < 576) {
      setTimeout(() => {
        this.headerService.hide();
      }, 0);
    }
  }
  openDatePicker() {
    this.picker?.open();
  }

  editProfileForm(): FormGroup {
    return new FormGroup({
      fullName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z\s]+$/),
        Validators.maxLength(30),
      ]),
      userName: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(30),
          this.customUsernameValidator(),
        ],
        [this.customCheckUserValidator(this.userService)]
      ),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      dateOfBirth: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl(
        { value: '', disabled: this.completeProfile },
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]
      ),
      genderType: new FormControl('', [Validators.required]),
    });
  }

  customUsernameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;
      if (value.startsWith('.') || value.endsWith('.')) {
        return { periodAtStartEnd: true };
      } else if (/\.\./.test(value)) {
        return { successivePeriods: true };
      } else if (/\s/.test(value)) {
        return { spacesNotAllowed: true };
      } else if (!/^(?!^\.|\.$)(?!.*\.{2})[a-zA-Z0-9_.]+$/.test(value)) {
        return { invalidCharacters: true };
      }
      return null;
    };
  }

  customCheckUserValidator(service: UserService): AsyncValidatorFn {
    return (
      control: AbstractControl<any, any>
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      const userName = control.value;
      this.userNameLoader = false;
      return service.checkUserName(this.userNameCheckObject(userName)).pipe(
        map((response: any) => {
          if (response.body.responseCode == 200) {
            this.userNameLoader = false;
            this.verifyUserNameResponseMessage = response.body?.responseMessage;
            return { userCheck: true } as ValidationErrors;
          } else {
            this.userNameLoader = false;
            return { userCheckFailed: true } as ValidationErrors;
          }
        }),
        catchError((error: any) => {
          const newUserName = this.editProfile.value['userName'].trim();
          const existingUsername = this.userDetails?.userName;
          this.userNameLoader = false;
          if (newUserName !== existingUsername) {
            this.verifyUserNameResponseMessage = error.error?.responseMessage;
            return of({ userExists: true } as ValidationErrors);
          }
          return of(null);
        })
      );
    };
  }

  userNameCheckObject(userName: any) {
    return {
      userAccount: {
        userName: userName,
      },
    };
  }

  updateUserBody() {
    const mobileNumber =
      this.editProfile.value['mobileNumber'] || this.userDetails.mobileNumber;

    const dateOfBirth = this.editProfile.value['dateOfBirth'];
    const convertedDateOfBirth = dateOfBirth
      ? formatDate(dateOfBirth, 'yyyy-MM-dd', 'en-US')
      : null;
    return {
      userDetails: {
        fullName: this.editProfile.value['fullName']
          .split(' ')
          .filter((str: any) => str !== '')
          .join(' '),
        dateOfBirth: convertedDateOfBirth,
        profileImage: this.uploadedImage ? this.uploadedImage : this.imageKey,
      },
      userAccount: {
        userName: this.editProfile.value['userName'],
        mobileNumber: mobileNumber,
        genderType: this.editProfile.value['genderType'],
      },
    };
  }

  onSelect(event: any) {
    if (event.target.files) {
      let file = event.target.files[0];
      if (
        !file.type ||
        (file.type !== 'image/jpeg' && file.type !== 'image/png')
      ) {
        this.snackBarService.openSnackBar(
          'Only jpg/jpeg & png files are allowed!',
          'success-snackbar'
        );
        return;
      }
      const trimmedFileName = file?.name.replace(/\s/g, '');
      const trimmedFile = new File([file], trimmedFileName, {
        type: file.type,
      });
      if (
        trimmedFile &&
        (trimmedFile.type == 'image/jpeg' || trimmedFile.type == 'image/png')
      ) {
        this.isImageUpdated = true;
        var reader = new FileReader();
        reader.readAsDataURL(trimmedFile);
        reader.onload = (event: any) => {
          this.selectedImage = event.target.result;
          this.URL = event.target.result;
          this.imageUrl = this.URL;
          this.editProfile.patchValue({ profileImage: trimmedFile });
          if (trimmedFile.size <= 1024 * 1024) {
            this.formData = new FormData();
            this.formData.append('file', trimmedFile);
          } else {
            this.imageCompress
              .compressFile(event.target.result, this.orientation, 50, 50)
              .then((result: any) => {
                const compressedFile = this.dataURItoBlob(result) as File;
                this.formData = new FormData();
                this.formData.append('file', compressedFile);
              });
          }
        };
      }
    }
  }

  handleImageError(event: any) {
    event.target.src = this.URL;
  }
  updateUserDetails(updateUserBody: any) {
    return {
      fullName: updateUserBody.userDetails.fullName,
      userName: updateUserBody.userAccount.userName,
      profileImage: this.uploadedImage ? this.uploadedImage : this.imageKey,
    };
  }
  uploadImage() {
    if (this.isImageUpdated) {
      this.storageBlobService
        .uploadImage(this.formData)
        .subscribe((data: any) => {
          this.uploadedImage = data.body.responseBody[0];
          this.update();
          this.isImageUpdated = false;
        });
    }
  }
  getProfileImage(): string {
    if (this.uploadedImage) {
      return this.imageKey;
    } else {
      return this.imageUrl;
    }
  }

  update() {
    if (this.isFormValid() && !this.isCompleteProfile()) {
      this.sendOTP();
    } else {
      this.updateProfile();
    }
  }
  sendOTP() {
    this.loaderService.show();
    this.otpService
      .sendOTPToMobile(this.editProfile.value.mobileNumber)
      .subscribe({
        next: (response: any) => {
          if (response.responseCode == 201) {
            this.loaderService.hide();
            this.otpTimerService.startTimer();
            this.dialog.open(OtpTransferComponent, {
              panelClass: ['mobile-dialog-box', 'border-radius'],
              disableClose: true,
              data: { updateUserBody: this.updateUserBody() },
            });
          }
        },
        error: (response: any) => {
          this.loaderService.hide();
          this.verifyOtpResponseMessage = response?.error?.responseMessage;
          this.snackBarService.openSnackBar(
            response?.error?.responseMessage,
            'error-snackbar'
          );
        },
      });
  }

  updateProfile() {
    this.loaderService.show();
    if (this.isFormValid() && this.isCompleteProfile()) {
      this.userService.updateUser(this.updateUserBody()).subscribe({
        next: (data: any) => {
          if (data.body.responseCode == 201) {
            this.loaderService.hide();
            this.headerService.updatefullName(
              this.updateUserBody().userDetails.fullName
            );
            this.snackBarService.openSnackBar(
              data.body.responseMessage,
              'success-snackbar'
            );
            this.fetchUserDetails();
            const userDetailsString = this.dataTransferService.getUserDetails();
            const existingUserDetails = userDetailsString
              ? JSON.parse(userDetailsString)
              : {};
            const updatedUserDetails = this.updateUserDetails(
              this.updateUserBody()
            );
            const mergedUserDetails = {
              ...existingUserDetails,
              ...updatedUserDetails,
            };
            this.dataTransferService.setUserDetails(
              JSON.stringify(mergedUserDetails)
            );
          }
        },
        error: (data) => {
          this.loaderService.hide();
          if (data.status == 0) {
            this.snackBarService.openSnackBar(
              'Internal server error. Please try again!',
              'error-snackbar'
            );
          } else {
            this.snackBarService.openSnackBar(
              data.error.responseMessage,
              'error-snackbar'
            );
          }
        },
      });
    } else {
      this.loaderService.hide();
      this.editProfile.markAllAsTouched();
    }
    this.updateSearchHistoryFullname();
  }

  fetchUserDetails() {
    this.userService.fetchUserDetails().subscribe({
      next: (response: any) => {
        if (response.body.responseCode == 200) {
          this.userDetails = response.body.responseBody;
          this.editProfile.controls['fullName']?.setValue(
            response.body.responseBody.fullName
          );
          this.editProfile.controls['userName']?.setValue(
            (this.currentuser = this.existingUserName =
              response.body.responseBody.userName)
          );
          this.editProfile.controls['email']?.setValue(
            response.body.responseBody.email
          );
          this.editProfile.controls['mobileNumber']?.setValue(
            response.body.responseBody.mobileNumber
          );
          this.editProfile.controls['dateOfBirth']?.setValue(
            response.body.responseBody.dateOfBirth
          );
          this.editProfile.controls['isPrivate']?.setValue(
            response.body.responseBody.userAccountPrivate
          );
          this.editProfile.controls['genderType']?.setValue(
            response.body.responseBody.genderType
          );
        }
        this.imageKey = response.body.responseBody.profileImage;
        this.fetchUserImage(this.imageKey);
      },
      error: (data) => {
        this.errorMessage = data.error?.responseMessage;
      },
    });
  }

  fetchUserImage(imageKey: string) {
    if (imageKey != null) {
      this.imageUrl = imageKey;
    } else {
      this.imageUrl = null;
    }
    if (this.imageUrl != null) {
      this.userService.setEditProfileImage(this.imageUrl);
    }
  }

  dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  isFormModified() {
    this.momentDate = new Date(this.editProfile?.value['dateOfBirth']);
    this.formattedDate = moment(this.momentDate).format('YYYY-MM-DD');
    return (
      this.editProfile?.value['userName'] != this.userDetails.userName ||
      this.editProfile?.value['fullName'] != this.userDetails.fullName ||
      this.formattedDate != this.userDetails.dateOfBirth ||
      (this.editProfile?.value['mobileNumber'] !=
        this.userDetails.mobileNumber &&
        this.editProfile?.value['mobileNumber'] != undefined) ||
      this.isImageUpdated ||
      this.editProfile?.value['genderType'] != this.userDetails.genderType
    );
  }

  isUserNameValid() {
    const newUserName = this.editProfile.value['userName'].trim();
    const existingUsername = this.userDetails?.userName;
    if (newUserName !== existingUsername) {
      return (
        !this.editProfile?.controls['userName'].hasError('periodAtStartEnd') &&
        !this.editProfile?.controls['userName'].hasError('successivePeriods') &&
        !this.editProfile?.controls['userName'].hasError('spacesNotAllowed') &&
        !this.editProfile?.controls['userName'].hasError('invalidCharacters') &&
        !this.editProfile?.controls['userName'].hasError('minLength') &&
        !this.editProfile?.controls['userName'].hasError('maxLength') &&
        !this.editProfile?.controls['userName'].hasError('required') &&
        !this.editProfile?.controls['userName'].hasError('userExists') &&
        this.editProfile?.controls['userName'].hasError('userCheck')
      );
    } else {
      return true;
    }
  }
  isCompleteProfile(): boolean {
    const userDetails = this.dataTransferService.getUserDetails();
    this.completeProfile = JSON.parse(userDetails)?.completeProfile;
    return userDetails && JSON.parse(userDetails)?.completeProfile;
  }
  isFormValid(): boolean {
    const isButtonDisable =
      this.editProfile?.controls['dateOfBirth'].valid &&
      this.editProfile?.controls['fullName'].valid &&
      this.editProfile?.controls['genderType']?.valid &&
      this.isFormModified() &&
      this.isUserNameValid();

    if (this.isCompleteProfile()) {
      return (
        isButtonDisable &&
        (this.isImageUpdated || this.isImageUpdated !== this.imageKey)
      );
    }

    if (this.imageUrl === null) {
      return (
        isButtonDisable &&
        this.isImageUpdated &&
        this.editProfile?.controls['mobileNumber']?.valid
      );
    }

    return (
      isButtonDisable &&
      (this.isImageUpdated || this.isImageUpdated !== this.imageKey) &&
      this.editProfile?.controls['mobileNumber']?.valid
    );
  }

  dateValidation() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const minYear = currentYear - 120;
    const maxYear = currentYear - 13;
    this.minDate = new Date(minYear, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setFullYear(maxYear);
  }

  updateSearchHistoryFullname() {
    this.profile = JSON.parse(this.dataTransferService.getUserDetails() || '');
    const searchHistory = JSON.parse(
      localStorage.getItem('searchHistory') || '[]'
    ) as Name[];
    const searchItemIndex = searchHistory.findIndex(
      (item) => item.userName === this.profile.userName
    );
    if (searchItemIndex != -1) {
      searchHistory[searchItemIndex].fullName = this.profile.fullName;
      searchHistory[searchItemIndex].profile = this.profile.profileImage;
    } else {
      searchHistory.splice(searchItemIndex, 1);
    }
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }
}
