<div class="non-scrollable">
  <div class="pt-3 px-3 pb-2">
    <mat-icon class="material-icons pointer px-1" (click)="closeDialog()"
      >close</mat-icon
    >
  </div>
  <mat-tab-group
    mat-align-tabs="center"
    [selectedIndex]="selectedTab === 'followers' ? 0 : 1"
  >
    <mat-tab label="Followers" labelClass="custom-tab-label ">
      <mat-divider class="color"></mat-divider>
      <div
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollFollowers()"
        *ngIf="
          this.userFollowerCount !== 0 &&
          this.myFollowerCount !== 0 &&
          !data.brand
        "
        class="scrolling"
      >
        <div
          class="d-flex align-items-center justify-content-between py-2 px-3"
          *ngFor="let follower of myAndUserFollowers; let i = index"
        >
          <div
            class="d-flex flex-row pointer"
            (click)="navigateToUserProfile(follower.loginResponseDTO.userName)"
          >
            <div class="mx-2 my-1 d-flex align-items-center">
              <img
                class="profile-image-40 rounded-object profile-color fill profile-border"
                [src]="
                  follower.loginResponseDTO.profileImage !== null
                    ? blobStorageLink + follower.loginResponseDTO.profileImage
                    : defaultImage
                "
                mat-card-avatar
              />
            </div>
            <div class="d-flex flex-column">
              <div class="pt-1 truncate-ellipsis w-100">
                <p class="fw-600 fs-16 m-0 hoverable">
                  {{ follower?.loginResponseDTO?.fullName }}
                </p>
              </div>

              <div class="truncate-ellipsis">
                <p class="fw-400 fs-12 m-0 scream-icon-color">
                  @{{ follower?.loginResponseDTO?.userName }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-2 ms-3 text-center" *ngIf="follower !== filteredUser">
            <button
              mat-raised-button
              class="btn background-white-color"
              type="button"
              *ngIf="!follower.loginResponseDTO.following"
              (click)="follow(follower.loginResponseDTO)"
            >
              <div class="d-flex">
                <div
                  class="fw-600 lato-font-family follow-padding py-2 blue-color"
                >
                  Follow
                </div>
              </div>
            </button>
            <button
              mat-raised-button
              class="btn background-color white-color w-100"
              type="button"
              *ngIf="follower.loginResponseDTO.following"
              (click)="follow(follower.loginResponseDTO)"
            >
              <div class="d-flex">
                <div class="fw-600 lato-font-family follow-padding py-2">
                  Following
                </div>
              </div>
            </button>
          </div>
        </div>
        <app-follower-shimmer *ngIf="followerShimmer"></app-follower-shimmer>
        <div class="box"></div>
      </div>

      <div
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollFollowers()"
        *ngIf="this.userFollowerCount !== 0 && data.brand"
        class="scrolling"
      >
        <div
          class="d-flex align-items-center py-2 px-3"
          *ngFor="let follower of brandFollowers; let i = index"
        >
          <div
            class="d-flex flex-row pointer"
            (click)="navigateToUserProfile(follower.userName)"
          >
            <div class="mx-2 my-1 d-flex align-items-center">
              <img
                class="profile-image-40 rounded-object profile-color fill profile-border"
                [src]="
                  follower.profileImage !== null
                    ? blobStorageLink + follower.profileImage
                    : defaultImage
                "
                mat-card-avatar
              />
            </div>
            <div class="d-flex flex-column">
              <div class="pt-1 truncate-ellipsis w-100">
                <p class="fw-600 fs-16 m-0 hoverable">
                  {{ follower?.fullName }}
                </p>
              </div>

              <div class="truncate-ellipsis">
                <p class="fw-400 fs-12 m-0 scream-icon-color">
                  @{{ follower?.userName }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="mt-2 ms-3 text-center"
            *ngIf="follower?.userName !== profile.userName"
          >
            <button
              mat-raised-button
              class="btn background-white-color"
              type="button"
              *ngIf="!follower.following"
              (click)="follow(follower)"
            >
              <div class="d-flex">
                <div
                  class="fw-600 lato-font-family follow-padding py-2 blue-color"
                >
                  Follow
                </div>
              </div>
            </button>
            <button
              mat-raised-button
              class="btn background-color white-color w-100"
              type="button"
              *ngIf="follower.following"
              (click)="follow(follower)"
            >
              <div class="d-flex">
                <div class="fw-600 lato-font-family follow-padding py-2">
                  Following
                </div>
              </div>
            </button>
          </div>
        </div>
        <app-follower-shimmer *ngIf="followerShimmer"></app-follower-shimmer>
        <div class="box"></div>
      </div>
      <div
        *ngIf="
          (this.userFollowerCount === 0 || this.myFollowerCount === 0) &&
          !followerShimmer
        "
      >
        <i class="material-icons fs-32 d-flex pt-3 justify-content-center"
          >person_add_alt</i
        >
        <div *ngIf="this.myFollowerCount === 0">
          <p class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400">
            No followers yet
          </p>
        </div>
        <div *ngIf="this.userFollowerCount === 0 && !data.brand">
          <p class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400">
            No one is following {{ userName }} yet
          </p>
        </div>
        <div *ngIf="data.brand">
          <p class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400">
            No one is following {{ data.brandName }} yet
          </p>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Following" labelClass="custom-tab-label">
      <mat-divider class="color"></mat-divider>
      <div
        infinite-scroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollFollowing()"
        *ngIf="
          this.userFollowingCount !== 0 &&
          this.myFollowingCount !== 0 &&
          !data.brand
        "
        class="scrolling"
      >
        <div
          class="d-flex align-items-center justify-content-between py-2 px-3"
          *ngFor="let followee of myAndUserFollowing; let i = index"
        >
          <div
            *ngIf="followee?.brandDTO === null"
            class="d-flex flex-row pointer"
            (click)="navigateToUserProfile(followee.loginResponseDTO.userName)"
          >
            <div class="mx-2 my-1 d-flex align-items-center">
              <img
                class="profile-image-40 rounded-object profile-color fill profile-border"
                [src]="
                  followee?.loginResponseDTO?.profileImage !== null
                    ? blobStorageLink + followee.loginResponseDTO.profileImage
                    : defaultImage
                "
                mat-card-avatar
              />
            </div>
            <div class="d-flex flex-column">
              <div class="pt-1 truncate-ellipsis w-100">
                <p class="fw-600 fs-16 m-0 hoverable">
                  {{ followee?.loginResponseDTO?.fullName }}
                </p>
              </div>
              <div class="truncate-ellipsis">
                <p class="fw-400 fs-12 m-0 scream-icon-color">
                  @{{ followee?.loginResponseDTO?.userName }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center pointer"
            *ngIf="followee?.brandDTO !== null"
            (click)="navigateToBrandProfile(followee.brandDTO.brandName)"
          >
            <div class="mx-2 my-1 d-flex align-items-center">
              <img
                class="profile-image-40 rounded-object profile-border"
                [src]="
                  followee.brandDTO.logoImage
                    ? blobStorageLink + followee.brandDTO.logoImage
                    : businessDefaultImage
                "
                mat-card-avatar
                alt="brand-profile"
              />
            </div>
            <div class="d-flex flex-column wrapper">
              <div class="pt-1 d-flex align-items-center">
                <div class="w-100">
                  <p class="fw-600 fs-16 m-0 hoverable truncate-ellipsis">
                    {{ followee.brandDTO.brandName }}
                  </p>
                </div>
                <div>
                  <span
                    class="ms-1 material-icons active-link icon-height fs-16"
                    [ngClass]="
                      this.dataTransferService.setVerificationIconColor(
                        followee?.brandDTO
                      )
                    "
                    *ngIf="
                      followee?.brandDTO && isBrandVerified(followee?.brandDTO)
                    "
                  >
                    verified
                  </span>
                </div>
              </div>
              <div class="truncate-ellipsis">
                <p class="fw-400 fs-12 m-0 scream-icon-color">
                  @{{ followee?.brandDTO?.categoryName }}
                </p>
              </div>
            </div>
          </div>

          <div class="text-center" *ngIf="followee.loginResponseDTO !== null">
            <div *ngIf="followee !== filteredUserName">
              <button
                mat-raised-button
                class="btn background-white-color"
                type="button"
                *ngIf="!followee.loginResponseDTO?.following"
                (click)="follow(followee.loginResponseDTO)"
              >
                <div class="d-flex">
                  <div
                    class="fw-600 lato-font-family follow-padding py-2 blue-color"
                  >
                    Follow
                  </div>
                </div>
              </button>
              <button
                mat-raised-button
                class="btn background-color white-color w-100"
                type="button"
                *ngIf="followee.loginResponseDTO.following"
                (click)="follow(followee.loginResponseDTO)"
              >
                <div class="d-flex">
                  <div class="fw-600 lato-font-family follow-padding py-2">
                    Following
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div class="text-center" *ngIf="followee.brandDTO !== null">
            <button
              mat-raised-button
              class="btn background-white-color"
              type="button"
              *ngIf="!followee.brandDTO.following"
              (click)="followBrand(followee.brandDTO)"
            >
              <div class="d-flex">
                <div
                  class="fw-600 lato-font-family follow-padding py-2 blue-color"
                >
                  Follow
                </div>
              </div>
            </button>
            <button
              mat-raised-button
              class="btn background-color white-color w-100"
              type="button"
              *ngIf="followee.brandDTO.following"
              (click)="followBrand(followee.brandDTO)"
            >
              <div class="d-flex">
                <div class="fw-600 lato-font-family follow-padding py-2">
                  Following
                </div>
              </div>
            </button>
          </div>
        </div>
        <app-follower-shimmer *ngIf="followerShimmer"></app-follower-shimmer>
        <div class="box"></div>
      </div>
      <div
        *ngIf="
          (this.userFollowerCount === 0 ||
            this.myFollowerCount === 0 ||
            data.brand) &&
          !followerShimmer
        "
      >
        <i class="material-icons fs-32 d-flex pt-3 justify-content-center"
          >person_add_alt</i
        >
        <div *ngIf="this.myFollowingCount === 0">
          <p class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400">
            You are not following anyone yet
          </p>
        </div>
        <div *ngIf="this.userFollowingCount === 0 && !data.brand">
          <p
            class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400 name-wrap px-3"
          >
            {{ userName }} is not following anyone yet
          </p>
        </div>
        <div *ngIf="data.brand">
          <p
            class="py-4 d-flex pt-3 justify-content-center fs-16 fw-400 name-wrap px-3"
          >
            {{ data.brandName }} is not following anyone yet
          </p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
