<div class="container-fluid p-0 px-3 py-2 my-2">
  <div class="d-flex p-0">
    <div class="me-3">
      <img
        class="profile-image-40 rounded-object profile-border"
        [src]="setProfileImage()"
        alt="my-profile"
      />
    </div>
    <div class="container-fluid p-0">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <span class="custom fs-16 fw-600">{{
            response?.brandResponseDTO?.brandName ||
              response?.loginResponseDTO?.fullName
          }}</span
          ><span
            class="material-icons active-link fs-16 px-1"
            [ngClass]="
              this.dataTransferService.setVerificationIconColor(
                this.response?.brandResponseDTO
              )
            "
            *ngIf="
              response?.brandResponseDTO?.brandName &&
              verficication === 'VERIFIED'
            "
          >
            verified
          </span>
        </div>
        <div class="d-flex scream-icon-color">
          <p class="custom m-0 fw-600">
            {{
              response?.brandResponseDTO?.categoryName ||
                "@" + response?.loginResponseDTO?.userName
            }}
          </p>
          <i class="material-icons dot_icon mt-2 px-1 icon-align"
            >fiber_manual_record</i
          >
          <div class="flex-shrink-0">
            <p class="m-0 p-0 fw-600 fs-14 line">
              {{ response && response.createdTime | timestamp }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pre-pl">
    <div *ngIf="response?.response" class="pt-2">
      <pre class="fs-16 fw-400 lato-font-family pre-word-wrap m-0">
 {{ response?.response }}</pre
      >
    </div>
    <app-image-preview [scream]="response"></app-image-preview>
  </div>
</div>
