<div
  class="d-flex align-items-center px-3 mt-3"
  *ngIf="
    scream &&
    scream.screamStatus === 'CLOSED' &&
    scream.experienceStatus !== null
  "
>
  <div class="d-flex">
    <img
      class="profile-image-32 rounded-object m-0 profile-border pointer background-white-color"
      [src]="
        scream.loginResponseDTO.profileImage
          ? blobStorageLink + scream.loginResponseDTO.profileImage
          : defaultImage
      "
      alt="my-profile"
    />
    <img
      class="profile-image-32 rounded-object m-0 profile-border pointer background-white-color brand-image"
      [src]="
        scream.logoImage
          ? blobStorageLink + scream.logoImage
          : businessDefaultImage
      "
      alt="brand-profile"
    />
  </div>
  <div class="ms-4">
    <span
      class="fw-bold fs-16 responsive-overflow underline pointer"
      (click)="navigateToProfile()"
    >
      {{ scream.loginResponseDTO.fullName }}</span
    >
    <span class="fs-16">
      <span> is now feeling</span>
      {{
        (scream && scream.experienceStatus.toLowerCase()) ||
          scream.experienceStatus.toLowerCase()
      }}
      with
    </span>
    <span
      class="fw-bold fs-16 underline pointer"
      (click)="navigateToBrandProfile()"
      >{{ scream.brandName }}</span
    >
  </div>
</div>
<div
  *ngIf="
    scream &&
    scream.screamStatus === 'CLOSED' &&
    scream.experienceStatus !== null
  "
>
  <mat-divider class="m-3"></mat-divider>
</div>
<div class="px-3">
  <div class="d-flex justify-content-between mt-3">
    <div class="d-flex align-items-center">
      <div class="me-2">
        <img
          class="profile-image-40 rounded-object m-0 profile-border pointer"
          (click)="navigateToProfile()"
          [src]="
            this.scream?.loginResponseDTO.profileImage
              ? blobStorageLink + this.scream?.loginResponseDTO.profileImage
              : defaultImage
          "
          alt="my-profile"
        />
      </div>
      <div>
        <div class="d-flex">
          <p class="emotion fw-600 fs-16 m-0">
            <span
              class="custom m-0 fw-600 fs-16 black-color vertical-align pointer underline"
              (click)="navigateToProfile()"
            >
              {{ scream?.loginResponseDTO.fullName || profile?.fullName }}
            </span>
            <span
              class="material-icons active-link fs-20 vertical-align px-1 carousel-indicators"
              >verified</span
            >
            <span
              class="emotion"
              [ngClass]="scream.hateRate < 5 ? 'emotion' : 'negative-emotion'"
            >
              {{ setFeeling() }}
              {{
                (scream && screamEmotion[scream.hateRate]) || scream?.hateRate
              }}
            </span>
          </p>
        </div>
        <div class="d-flex scream-icon-color">
          <p
            class="m-0 custom fw-600 fs-14 pointer underline"
            (click)="navigateToProfile()"
          >
            @{{
              (scream && scream?.loginResponseDTO?.userName) ||
                profile?.userName
            }}
          </p>
          <mat-icon class="material-icons dot_icon mt-2 px-2"
            >fiber_manual_record</mat-icon
          >
          <div class="flex-shrink-0">
            <p class="m-0 p-0 fw-600 fs-14 line">
              {{ scream && scream.genericDetails?.createdTime | timestamp }}
            </p>
          </div>
          <mat-icon
            *ngIf="scream.privacy"
            class="material-icons dot_icon mt-2 px-2"
            >fiber_manual_record</mat-icon
          >
          <span
            class="material-symbols-outlined fs-14 mt-1 pointer"
            *ngIf="scream.privacy"
            (click)="openBottomSheetForIcons('private')"
          >
            lock
          </span>
        </div>

        <div
          *ngIf="
            scream &&
            (scream.screamStatus === 'CLOSED' ||
              scream.screamStatus === 'AUTOCLOSED')
          "
          class="d-flex align-items-center justify-content-center button status white-color"
        >
          {{ (scream && scream?.status) || "Resolved" }}
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center pos">
      <mat-icon
        *ngIf="isUserLoggedIn"
        class="ms-2 fs-20 mt-1 pointer px"
        (click)="openBottomSheet(scream)"
        >more_vert</mat-icon
      >

      <mat-icon
        *ngIf="isUserLoggedIn"
        class="ms-2 fs-20 mt-1 pf"
        [matMenuTriggerFor]="menu1"
        #menuTrigger
        (click)="handleIconClick()"
      >
        more_vert
      </mat-icon>

      <mat-menu #menu1="matMenu" class="delete-report-menu" xPosition="before">
        <button mat-menu-item *ngIf="isCardOpen" (click)="openDelete()">
          <span class="d-flex">
            <span class="material-icons-outlined">delete_outline</span>
            <p class="m-0 mx-3">Delete</p>
          </span>
        </button>
        <button *ngIf="isCardOpens" mat-menu-item (click)="openReport()">
          <span class="d-flex">
            <span class="material-icons-outlined">report</span>
            <p class="m-0 mx-3">Report</p>
          </span>
        </button>
      </mat-menu>

      <mat-icon
        mat-icon-button
        *ngIf="isBusinessLoggedIn && taggedBrands.includes(currentBrandName)"
        class="ms-2 fs-20 mt-1 pointer"
        [matMenuTriggerFor]="menu"
        #menuTrigger
        (menuOpened)="disableScroll()"
        (menuClosed)="enableScroll()"
        >more_vert</mat-icon
      >

      <mat-menu
        #menu="matMenu"
        backdropClass="cdk-global-scrollblock"
        xPosition="before"
      >
        <button
          mat-menu-item
          [disabled]="
            scream.hateRate > 4 ||
            (scream.screamStatus !== null && scream.screamStatus !== 'PENDING')
          "
          (click)="updateScreamStatus(scream?.suid, 'RESOLVED')"
        >
          <span class="d-flex">
            <span class="material-symbols-outlined m-0 align-items-center">
              verified_user
            </span>
            <p class="m-0 mx-3">Mark as resolved</p>
          </span>
        </button>
        <button
          mat-menu-item
          (click)="viewContact(scream?.suid)"
          [disabled]="!scream?.canAccessDetails"
        >
          <span class="d-flex">
            <span class="material-symbols-outlined m-0 align-items-center">
              contact_mail
            </span>
            <p class="m-0 mx-3">View Contact</p>
          </span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div
    #showMoreContent
    class="scream-content font fs-16 pt-2 m-0 wrap-it truncate"
    [ngClass]="{ truncate: !this.scream.showMore }"
    [innerHTML]="
      highlightBrand(
        scream && scream?.mentionedBrands,
        scream && scream?.scream
      )
    "
  ></div>
  <p
    class="active-link fs-14 pointer truncate"
    *ngIf="showMoreButton"
    (click)="expandScream()"
  >
    {{ this.scream.showMore ? "show less" : "show more" }}
  </p>
  <app-image-preview [scream]="scream"></app-image-preview>
  <app-toolbar [scream]="scream"></app-toolbar>
</div>
