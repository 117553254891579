<div class="container px-4 py-4">
  <span
    class="material-symbols-outlined pointer mb-3"
    (click)="goBackToPreviousPage()"
    >arrow_back</span
  >
  <div>
    <h1 class="fw-700 fs-20">Refund Policy</h1>
    <p>
      Thank you for choosing Scremer! We strive to provide the best possible
      experience for our users. This refund policy outlines the conditions under
      which you may be eligible for a refund on our services.
    </p>
    <h2 class="fw-700 fs-20">General Policy</h2>
    <ul>
      <li>
        Non-refundable Fees: Set-up fees, transaction fees, and any other
        non-recurring fees are non-refundable.
      </li>
      <li>
        Subscription Fees: You can cancel your subscription at any time. If you
        cancel your subscription before the end of the current billing period,
        you will not be charged for the next period. We do not offer refunds for
        partially used billing periods.
      </li>
    </ul>
    <h2 class="fw-700 fs-20">Eligibility for Refunds</h2>
    <ul>
      <li>
        Technical Issues: If you experience technical issues with the Scremer
        service that render it unusable and cannot be resolved within a
        reasonable timeframe, you may be eligible for a full refund.
      </li>
      <li>
        Unintentional Purchase: If you unintentionally purchase a Scremer
        subscription or feature, you may be eligible for a full refund if you
        request it within 24 hours of the purchase and haven't used the service.
      </li>
      <li>
        Subscription Cancellation: If you cancel your subscription within 7 days
        of your initial activation, you may be eligible for a full refund.
      </li>
    </ul>
    <h3 class="fw-700 fs-20">How to Request a Refund</h3>
    <p>
      To request a refund, please contact our Support team through
      <a href="mailto:scream@scremer.com"> scream@scremer.com</a> within the
      timeframe outlined in this policy. Please be prepared to provide your
      account information and a detailed explanation for your refund request
    </p>
    <h2 class="fw-700 fs-20">Processing Time</h2>
    <p>
      Once we receive your refund request, we will review it and respond to you
      within 4-10 business days. If your request is approved, the refund will be
      processed within 8-10 business days and credited to your original payment
      method.
    </p>
    <h4 class="fw-700 fs-20">Changes to this Policy</h4>
    <p>
      We reserve the right to update this refund policy at any time by posting
      the revised policy on this website. The revised policy will be effective
      for all purchases made after the posting date.
    </p>
  </div>
</div>
