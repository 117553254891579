import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar, private router: Router) {}
  public openSnackBar(message: string, className: string): void {
    this.snackBar.open(message, '', {
      duration: 5000,
      panelClass: [className],
    });
  }
  public dismissSnackBar() {
    this.snackBar.dismiss();
  }
  public openViewScreamSnackBar(suid: string) {
    this.snackBar
      .open('Your scream has been posted', 'View', {
        duration: 5000,
        panelClass: ['view-snackbar'],
      })
      .onAction()
      .subscribe(() => {
        this.router.navigate(['/scream/'], {
          queryParams: {
            suid: suid,
          },
          queryParamsHandling: 'merge',
          onSameUrlNavigation: 'reload',
          replaceUrl: true,
        });
      });
  }
  public showTooltipSource = new Subject<boolean>();
  showTooltip$ = this.showTooltipSource.asObservable();

  showTooltip(value: boolean) {
    this.showTooltipSource.next(value);
  }
  public focusTextareaSource = new Subject<void>();
  focusTextarea$ = this.focusTextareaSource.asObservable();

  focusTextarea() {
    this.focusTextareaSource.next();
  }
}
