<div
  *ngIf="
    !router.url?.startsWith('/business') &&
    !router.url?.includes('/edit-profile')
  "
>
  <ng-container *ngIf="currentState">
    <div class="container-fluid p-0">
      <div class="mx-auto">
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100">
            <div class="pb-3 px-4">
              <mat-icon
                class="material-icons pointer mb-0 ml-4 mt-4 mr-0"
                (click)="closeDialog()"
                >close</mat-icon
              >
            </div>

            <h1 class="fs-23 mb-0 fw-600 px-4" *ngIf="isContentVisible">
              Enter Code
            </h1>
            <h1 class="fs-23 mb-0 fw-600 px-4" *ngIf="!isContentVisible">
              Email Verification
            </h1>
            <div class="py-3 px-4">
              <p class="h6 mb-0" *ngIf="isContentVisible">
                Please enter the code sent on your email to reset password
              </p>
              <p class="h6 mb-0" *ngIf="!isContentVisible">
                Please enter the code that has been sent on your email to
                proceed.
              </p>
            </div>
            <div class="px-4 pb-3">
              <app-one-time-password #onetimepassword></app-one-time-password>
            </div>
            <div
              class="d-flex justify-content-end fs-14 fw-400 px-5"
              *ngIf="!isResendOTPVisible()"
            >
              <p class="pb-4 mb-0">
                Resend OTP in {{ otpTimerService.timerDisplay }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center mb-0 pb-4"
              *ngIf="isResendOTPVisible()"
            >
              <p class="mb-0">Didn’t receive otp?</p>

              <a class="pointer mx-2" (click)="resend()">Resend</a>
            </div>
            <div class="d-flex justify-content-center px-4">
              <button
                *ngIf="isContentVisible"
                mat-raised-button
                class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 py-1"
                (click)="next()"
                [disabled]="!onetimepassword.oneTimePassword()"
                [ngClass]="{
                  'disabled-button': !onetimepassword.oneTimePassword()
                }"
              >
                Next
              </button>
              <button
                *ngIf="!isContentVisible"
                mat-raised-button
                class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 py-1"
                (click)="verifyUserAccount()"
              >
                Verify account
              </button>
            </div>
            <div>
              <mat-error class="text-center pt-3 pb-4">
                {{ errorMessage }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-reset-password *ngIf="!currentState"></app-reset-password>
</div>
<div *ngIf="router.url?.startsWith('/business')">
  <ng-container *ngIf="currentState">
    <div class="container p-0">
      <div class="mx-auto">
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100">
            <div class="px-4">
              <mat-icon
                class="material-icons pointer mb-0 ml-4 mt-4 mr-0"
                (click)="closeDialog()"
                >close</mat-icon
              >
            </div>
            <h1 *ngIf="!isContentVisible" class="fs-23 fw-600 px-4 m-0 pt-3">
              Verify your account
            </h1>
            <h1 *ngIf="isContentVisible" class="fs-23 fw-600 px-4 m-0 pt-3">
              Enter Code
            </h1>
            <div class="py-3 px-4">
              <p class="h6 m-0" *ngIf="!isContentVisible">
                Please enter the OTP sent to your email address to verify your
                account
              </p>
              <p
                class="h6 m-0"
                *ngIf="
                  isContentVisible &&
                  !router.url?.startsWith('/business/cancel-plan')
                "
              >
                Please enter the OTP sent to your email address to reset your
                password
              </p>
              <p
                class="h6 m-0"
                *ngIf="router.url?.startsWith('/business/cancel-plan')"
              >
                Please enter the OTP sent to your email address to cancel your
                plan
              </p>
            </div>
            <div class="pb-3 mobile-screen">
              <app-one-time-password #onetimepassword></app-one-time-password>
            </div>
            <div
              class="d-flex justify-content-end fs-14 fw-400 px-5"
              *ngIf="!isResendOTPVisible()"
            >
              <p class="m-0" *ngIf="!isContentVisible">
                Resend OTP in {{ otpTimerService.timerDisplay }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center m-0"
              *ngIf="isResendOTPVisible()"
            >
              <p class="m-0" *ngIf="!isContentVisible">Didn’t receive otp?</p>

              <a
                class="pointer mx-2"
                *ngIf="!isContentVisible"
                (click)="businessVerifyResend()"
                >Resend</a
              >
            </div>
            <div
              class="d-flex justify-content-end fs-14 fw-400 px-5"
              *ngIf="!isBusinessResendOTPVisible()"
            >
              <p class="m-0" *ngIf="isContentVisible">
                Resend OTP in {{ otpTimerService.timerDisplay }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center m-0"
              *ngIf="isBusinessResendOTPVisible()"
            >
              <p class="m-0" *ngIf="isContentVisible">Didn’t receive otp?</p>

              <a
                class="pointer mx-2"
                *ngIf="isContentVisible"
                (click)="resendBusinessForgotPasswordOtp()"
                >Resend</a
              >
            </div>
            <div class="d-flex justify-content-center px-4 pt-4">
              <button
                *ngIf="!isContentVisible"
                mat-raised-button
                class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 py-1"
                (click)="verifyBusinessAccount()"
              >
                Verify account
              </button>
              <button
                *ngIf="isContentVisible"
                mat-raised-button
                class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 py-1"
                (click)="submitForgotPasswordOtp()"
                [disabled]="!onetimepassword.oneTimePassword()"
                [ngClass]="{
                  'disabled-button': !onetimepassword.oneTimePassword()
                }"
              >
                Next
              </button>
            </div>
            <div>
              <mat-error class="text-center pt-3 pb-4">
                {{ errorMessage }}
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <app-reset-password *ngIf="!currentState"></app-reset-password>
</div>
<div *ngIf="router.url?.includes('/edit-profile')">
  <div class="container-fluid p-0">
    <div class="mx-auto">
      <div class="d-flex justify-content-center align-items-center">
        <div class="w-100">
          <div class="pb-3 px-4">
            <mat-icon
              class="material-icons pointer mb-0 ml-4 mt-4 mr-0"
              (click)="closeDialog()"
              >close</mat-icon
            >
          </div>

          <h1 class="fs-23 mb-0 fw-600 px-4">Enter Code</h1>
          <div class="py-3 px-4">
            <p class="h6 mb-0">
              Please enter the OTP sent to
              <span class="fs-18 fw-700">
                {{
                  updatedUserDetails.updateUserBody.userAccount.mobileNumber
                }}</span
              >
            </p>
          </div>
          <div class="px-4 pb-3">
            <app-one-time-password #onetimepassword></app-one-time-password>
          </div>
          <div
            class="d-flex justify-content-end fs-14 fw-400 px-5"
            *ngIf="!isResendOTPVisible()"
          >
            <p class="pb-4 mb-0">
              Resend OTP in {{ otpTimerService.timerDisplay }}
            </p>
          </div>
          <div
            class="d-flex justify-content-end fs-14 fw-400 px-5"
            *ngIf="isResendOTPVisible()"
          >
            <p class="pb-4 mb-0">
              <a class="pointer mx-2" (click)="resendSMSOtp()">Resend OTP</a>
            </p>
          </div>
          <div class="d-flex justify-content-center px-4">
            <button
              *ngIf="isContentVisible"
              mat-raised-button
              class="button btn-primary background-color w-100 lato-font-family white-color fw-500 fs-16 py-1"
              (click)="sendSMS()"
              [disabled]="!onetimepassword.oneTimePassword()"
              [ngClass]="{
                'disabled-button': !onetimepassword.oneTimePassword()
              }"
            >
              Submit
            </button>
          </div>
          <div>
            <mat-error class="text-center pt-3 pb-4">
              {{ errorMessage || verifyOtpResponseMessage }}
            </mat-error>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
