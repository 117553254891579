import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { CreateAScreamComponent } from '../../scream/create-a-scream/create-a-scream.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HateRateSliderComponent } from '../hate-rate-slider/hate-rate-slider.component';
import { Router } from '@angular/router';
import * as e from 'express';
@Component({
  selector: 'app-profile-and-hate-rate-slider',
  templateUrl: './profile-and-hate-rate-slider.component.html',
  styleUrls: ['./profile-and-hate-rate-slider.component.scss'],
})
export class ProfileAndHateRateSliderComponent implements OnInit {
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  @ViewChild(HateRateSliderComponent) hateRateSlider!: HateRateSliderComponent;
  userProfileImage: any;
  sliderValue: any;
  isDialogOpen: boolean = false;
  isSliderchangd: boolean = false;

  constructor(
    private readonly dataTransferService: DataTransferService,
    public dialog: MatDialog,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
  }
  slideChanges(event: any) {
    // console.log(this.isDialogOpens, 'che');
    console.log(this.isSliderchangd, 'chan');

    if (!this.isDialogOpen && this.isSliderchangd) {
      this.isDialogOpen = true;
      this.dialog
        .open(CreateAScreamComponent, {
          data: { event: this.sliderValue, isSliderSelected: true },
          panelClass: ['scream-dialog-box', 'border-radius'],
          disableClose: true,
        })
        ?.afterClosed()
        .subscribe((event) => {
          event = this.hateRateSlider.reset();
          this.isDialogOpen = false;
          this.isSliderchangd = false;
        });
    }
  }

  getSliderValue(event: any) {
    this.isSliderchangd = true;
    this.sliderValue = event;
  }
}
