<div class="container-fluid p-0 m-0">
  <div class="screambuttonSticky px-3 pb-2 pt-3">
    <div class="row">
      <div class="col-8 d-flex align-items-center">
        <mat-icon
          (click)="goBackToPreviousPage()"
          class="material-symbols-outlined pointer black-color"
        >
          close
        </mat-icon>
        <app-scream-tag
          [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
          [brands]="screamBrands"
        ></app-scream-tag>
      </div>
      <div
        [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
        class="col-4 d-flex justify-content-end align-items-center"
      >
        <button
          mat-button
          class="btn-primary background-color lato-font-family white-color button px-4 fs-16 fw-500"
          [disabled]="disableScreamButton()"
          [ngClass]="{ 'disabled-button': disableScreamButton() }"
          (click)="scream()"
        >
          Scream
        </button>
      </div>
    </div>
    <div class="pt-2 row">
      <div class="d-flex px-3 profile-container-height">
        <div class="d-flex align-items-center justify-content-center">
          <img
            class="profile-image-32 profile-border rounded-object p-0"
            [src]="
              userProfileImage
                ? blobStorageLink + userProfileImage
                : defaultImage
            "
            alt="user"
          />
        </div>
        <div
          [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
          class="d-flex mx-2"
        >
          <div class="d-flex" *ngIf="!isBrandEnabledPrivateScream">
            <mat-form-field id="select" class="privacy">
              <mat-select [(value)]="select" [formControl]="privacyControl">
                <mat-select-trigger class="d-flex">
                  <div class="row p-0 mx-1 w-100">
                    <mat-icon class="p-0 active-link fs-20 w-20">{{
                      select.icon
                    }}</mat-icon>
                    <div class="d-flex w-110 ml-8 p-0">
                      <div>
                        {{ select.viewValue }}
                      </div>
                      <mat-icon class="p-0 ml-4"
                        ><span class="active-link fs-20">
                          keyboard_arrow_down
                        </span></mat-icon
                      >
                    </div>
                  </div>
                </mat-select-trigger>
                <h4 class="my-1 px-3 fs-16 fw-500">Who can see your scream?</h4>
                <mat-option
                  class="px-2"
                  *ngFor="let privacy of privacy"
                  [value]="privacy"
                >
                  <div class="d-flex">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <mat-icon
                        class="fs-20 m-0 p-0"
                        [ngClass]="
                          select === privacy
                            ? 'active-link'
                            : 'scream-icon-color'
                        "
                        >{{ privacy.icon }}</mat-icon
                      >
                    </div>
                    <div class="px-1">
                      <p class="m-0 fs-14">{{ privacy.viewValue }}</p>
                      <p class="m-0 fs-13 black-color">
                        {{ privacy.privacyHint }}
                      </p>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            *ngIf="isBrandEnabledPrivateScream"
          >
            <div class="row p-0 mx-1 w-100">
              <mat-icon class="p-0 active-link fs-20 w-20">{{
                privacy[1].icon
              }}</mat-icon>
              <div class="d-flex w-110 ml-8 p-0">
                <p class="fs-16 m-0">
                  {{ privacy[1].viewValue }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-3">
    <app-scream-field
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      (sendBrand)="setBrand($event)"
      (sendScream)="setScream()"
      (brandRequestClickEvent)="onBrandRequestClick()"
    ></app-scream-field>
    <app-upload-image
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      [noOfImages]="noOfUploadImages"
      (newImageEvt)="onUploadImage($event)"
      [addedImages]="screamImages"
    ></app-upload-image>
  </div>

  <div class="toolbarSticky px-3 pb-3">
    <mat-hint
      *ngIf="
        this.stripHtmlTags(stripedScream) &&
        this.stripHtmlTags(stripedScream).trim().length <= 500
      "
      class="scream-icon-color d-flex justify-content-end"
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      >{{ this.stripHtmlTags(stripedScream).trim().length }}/ 500</mat-hint
    >
    <mat-hint
      *ngIf="
        this.stripHtmlTags(stripedScream) &&
        this.stripHtmlTags(stripedScream).trim().length > 500
      "
      class="red-color d-flex justify-content-end"
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      >{{ 500 - this.stripHtmlTags(stripedScream).trim().length }}/
      500</mat-hint
    >
    <div class="row">
      <p class="fs-16 scream-icon-color">How are you feeling?</p>
    </div>

    <app-hate-rate-slider
      [slider]="this.data?.event || 0"
      (sliderValue)="getSliderValue($event)"
    ></app-hate-rate-slider>
    <div
      class="row px-3 py-3"
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
    >
      <div class="col-8 p-0">
        <h4 class="fs-16 fw-400 m-0 active-link">
          I consent brand to contact me
        </h4>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="isChecked" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <mat-divider
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
    ></mat-divider>
    <app-scream-toolbar
      [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      (emitImages)="addImageEvt()"
    ></app-scream-toolbar>
  </div>
</div>
