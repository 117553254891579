<div class="container px-3 mt-3">
  <app-back-arrow></app-back-arrow>
  <div class="d-flex mt-3 mb-2">
    <div class="pe-2 d-flex align-items-center">
      <img
        *ngIf="image === 'myProfile'"
        class="profile-image-88 rounded-object profile-border pointer"
        [src]="setProfileImage()"
        alt="my-profile"
      />
      <img
        *ngIf="image === 'userImage'"
        class="profile-image-88 rounded-object profile-border pointer"
        [src]="
          profile?.profileImage !== null
            ? blobStorageLink + profile?.profileImage
            : defaultImage
        "
        alt="user-profile"
      />
      <img
        *ngIf="image === 'brandImage'"
        class="profile-image-88 rounded-object profile-border pointer object-contain"
        [src]="
          profile?.logoImage
            ? blobStorageLink + profile?.logoImage
            : businessDefaultImage
        "
        alt="brand-profile"
      />
    </div>
    <div class="w-100 ps-2 profile-margin">
      <div
        class="d-flex justify-content-between text-center fs-16 fw-600 lato-font-family"
      >
        <div *ngIf="isScreamsVisible || !brand">
          <p class="p-0 m-0">{{ screamsCount }}</p>
          <p class="fw-400 fs-14 p-0 m-0">Screams</p>
        </div>
        <div
          class="pointer"
          (click)="
            brand
              ? openDialogFollowers('followers')
              : openBrandFollowers('followers')
          "
          *ngIf="isScreamsVisible || !brand"
        >
          {{ followersCount }}
          <p class="fw-400 fs-14 m-0 p-0">Followers</p>
        </div>
        <div
          class="pointer"
          (click)="
            brand
              ? openDialogFollowers('following')
              : openBrandFollowers('following')
          "
          *ngIf="isScreamsVisible || !brand"
        >
          {{ followingCount }}
          <p class="fw-400 fs-14 p-0 m-0">Following</p>
        </div>
      </div>
      <div class="d-flex align-items-center mt-3">
        <div *ngIf="isButtonVisible" class="w-100">
          <button
            mat-raised-button
            class="follow-btn background-color white-color w-100"
            (click)="goToEditProfile()"
          >
            <div class="fs-14 fw-600 lato-font-family">Edit profile</div>
          </button>
        </div>
        <div
          *ngIf="!isButtonVisible"
          class="w-100"
          (click)="brand ? follow() : followBrand()"
        >
          <button
            mat-raised-button
            class="follow-btn background-white-color w-100"
            type="button"
            *ngIf="!followed"
          >
            <div class="fw-600 fs-14 lato-font-family">Follow</div>
          </button>
          <button
            mat-raised-button
            class="follow-btn background-color white-color w-100"
            type="button"
            *ngIf="followed"
          >
            <span class="fw-600 fs-14 lato-font-family">Following</span>
          </button>
        </div>
        <div class="px-3 pointer">
          <mat-icon class="px" (click)="openShare(profile)">ios_share</mat-icon>
          <mat-icon
            (click)="openCard()"
            class="pf"
            [matMenuTriggerFor]="menu1"
            #menuTrigger
            >ios_share</mat-icon
          >
        </div>
        <mat-menu #menu1="matMenu" class="copy-link-menu1" xPosition="before">
          <button mat-menu-item *ngIf="isCardOpen" (click)="copyLink()">
            <span class="d-flex">
              <i class="material-icons">link</i>
              <p class="m-0 mx-3">Copy Link</p>
            </span>
          </button>
        </mat-menu>
        <mat-menu #menu2="matMenu">
          <button mat-menu-item *ngIf="isCardOpens" (click)="openReportUser()">
            <span class="d-flex">
              <span class="material-icons-outlined">report</span>
              <p class="m-0 mx-3">Report</p>
            </span>
          </button>
          <button mat-menu-item *ngIf="isCardOpens" (click)="blockUser()">
            <span class="d-flex">
              <span class="material-icons-outlined">block</span>
              <p class="m-0 mx-3">Block</p>
            </span>
          </button>
        </mat-menu>
        <mat-menu
          #menu2="matMenu"
          class="delete-report-menu2"
          xPosition="before"
        >
          <button mat-menu-item *ngIf="isCardOpens" (click)="openReportUser()">
            <span class="d-flex">
              <span class="material-icons-outlined">report</span>
              <p class="m-0 mx-3">Report</p>
            </span>
            <span class="d-flex">
              <span class="material-icons-outlined">block</span>
              <p class="m-0 mx-3">Block</p>
            </span>
          </button>
        </mat-menu>
        <div *ngIf="!isButtonVisible">
          <mat-icon
            *ngIf="image !== 'brandImage'"
            class="px1"
            (click)="openBottomSheet(profile, user)"
            >more_vert</mat-icon
          >
          <mat-icon
            *ngIf="image !== 'brandImage'"
            class="fs-24 pointer"
            (click)="openCards()"
            class="pf1"
            [matMenuTriggerFor]="menu2"
            #menuTrigger
            >more_vert
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="d-inline-flex mt-2">
    <p class="fs-16 fw-700 lato-font-family mb-0 name-wrap">
      {{ profile?.fullName || profile?.brandName }}
      <span
        class="material-icons active-link verified-position fs-16"
        [ngClass]="this.dataTransferService.setVerificationIconColor(profile)"
        *ngIf="profile?.brandName && verification === 'VERIFIED'"
      >
        verified
      </span>
    </p>
    <span
      class="material-symbols-outlined active-link verified px-1 fs-16"
      *ngIf="
        profile?.brandName &&
        (verification === 'NOT_VERIFIED' || verification === 'IN_PROGRESS')
      "
      matTooltip="This brand hasn’t been verified yet on scremer"
      [matTooltipPosition]="tooltipPosition"
      matTooltipClass="myTooltip"
    >
      info
    </span>
  </div>
  <div>
    <p class="fw-600 fs-14 scream-icon-color lato-font-family">
      <span *ngIf="image === 'myProfile' || image === 'userImage'">@</span
      >{{ profile?.userName || profile?.categoryName }}
    </p>
  </div>
</div>
<mat-divider
  *ngIf="image === 'myProfile' || image === 'userImage'"
></mat-divider>
