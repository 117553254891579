<mat-card
  class="search-card"
  [ngClass]="show ? 'mobile-search-card' : 'carousel-indicators'"
>
  <div
    class="header-size background-color padding d-flex align-items-center display-mobiles-only"
  >
    <mat-icon
      class="material-symbols-outlined pointer white-color me-3"
      (click)="goBackToPreviousPage()"
      >arrow_back</mat-icon
    >
    <div
      class="w-100 background-white-color white-div d-flex align-items-center py-2"
    >
      <mat-icon
        class="material-icons-outlined pointer fs-20 ms-12 me-2"
        [ngClass]="[searchItem ? '' : 'grey-disable-color']"
        >search</mat-icon
      >
      <input
        type="text"
        #myInput
        placeholder="Search scremer"
        class="fw-400 fs-16 p-0 me-2"
        [(ngModel)]="searchItem"
        (keyup)="getAllAppropriateSearchResults($event)"
        (keyup.enter)="submitForm()"
      />
      <mat-icon
        class="material-icons-outlined pointer fs-20 ms-2 me-12"
        (click)="clearAllSearchResults()"
        *ngIf="searchItem"
        >clear</mat-icon
      >
    </div>
  </div>

  <div *ngIf="getSearchHistory && getSearchHistory !== null && !searching">
    <h3 class="fw-700 fs-16 p-3 m-0">Recent searches</h3>
    <div
      class="hoverDiv pointer px-3"
      *ngFor="let search of getSearchHistory; let i = index"
      [ngClass]="{ selected: selectedRecentSearchIndex === i }"
      (click)="
        navigateToUserProfile(
          search?.userName,
          search?.fullName,
          search?.profile,
          search?.isUser,
          search?.isVerified
        )
      "
    >
      <app-search-history [search]="search"></app-search-history>
    </div>
  </div>

  <div
    class="p-3 hoverDiv pointer"
    *ngFor="let result of searchResults; let i = index"
    [ngClass]="{ selected: selectedResultIndex === i }"
    (click)="
      navigateToUserProfile(
        result[1],
        result[2],
        result[3],
        result[4],
        result[5]
      )
    "
  >
    <app-search-results [result]="result"></app-search-results>
  </div>

  <div
    *ngIf="
      userInput &&
      inputLength &&
      searchItem !== '' &&
      searchResults.length === 0
    "
    class="d-flex wrap-it mt-3 px-3 display-mobiles-only"
  >
    <div>
      <mat-icon class="material-icons-outlined mt-2 me-3 mat-icon-height"
        >search</mat-icon
      >
    </div>

    <div class="fw-400 fs-16">
      <p class="py-1 text-break">"{{ searchItem }}"</p>
    </div>
  </div>
</mat-card>
