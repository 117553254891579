import { Component, OnInit } from '@angular/core';
import { GetAppService } from '../core/service/getAppService/get-app.service';

@Component({
  selector: 'app-download-scremer',
  templateUrl: './download-scremer.component.html',
  styleUrls: ['./download-scremer.component.scss'],
})
export class DownloadScremerComponent implements OnInit {
  constructor(private getAppService: GetAppService) {}
  private testing: boolean = false;
  ngOnInit(): void {
    if (!this.testing) {
      this.getAppService.downloadApp();
    }
  }

  enableTesting() {
    this.testing = true;
  }

  disableTesting() {
    this.testing = false;
  }
}
