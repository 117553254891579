<span class="m-0">
  <div
    #editableDiv
    [contentEditable]="true"
    data-text="Scream here....."
    (mouseup)="tag()"
    #input
    (input)="tag()"
    (window:keydown)="handleKeyDown($event)"
    (keyup)="onKeyUp($event)"
    [innerHTML]="newContent"
    spellcheck="false"
    class="fs-16 outline-none scream-field-height"
    autofocus
  ></div>

  <div *ngIf="isSearching" class="border background-white-color p-2 scroll">
    <div
      class="d-flex align-items-center justify-content-center"
      *ngIf="isSpinnerLoading"
    >
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>

    <div
      *ngFor="let brand of brandsList; let i = index"
      [class.active]="activeBrandIndex === i"
      class="row mb-2 py-1 brand-hover pointer"
      (click)="onContentChange(brand)"
    >
      <span class="col-2 d-flex align-items-center justify-content-center">
        <img
          src="{{
            brand.logoImage
              ? blobStorageLink + brand.logoImage
              : businessDefaultImage
          }}"
          alt="{{ brand.brandName }}+logo"
          class="header-image"
        />
      </span>
      <span class="col-10">
        <p class="fs-16 fw-600 m-0">
          {{ brand.brandName }}

          <span
            class="material-icons active-link icon-height fs-16"
            *ngIf="
              brand?.brandName &&
              brand.documentVerificationStatus === 'VERIFIED'
            "
            [ngClass]="this.dataTransferService.setVerificationIconColor(brand)"
          >
            verified
          </span>
        </p>
        <p class="fs-12 grey-color m-0">
          {{ brand.categoryName }}
        </p>
      </span>
    </div>
    <div
      class="row active-link pointer brand-hover py-3 background-white-color position-request-brand"
    >
      <span class="col-2 d-flex align-items-center justify-content-center">
        <mat-icon mat-card-avatar class="w-auto h-100 m-0"
          >add_business</mat-icon
        >
      </span>
      <span class="col-10 d-flex align-items-center">
        <p (click)="navigateToBrandCreation()" class="fs-16 fw-600 m-0">
          Request for Brand
        </p>
      </span>
    </div>
  </div>
</span>
