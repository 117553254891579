<div class="px-3 pt-7 mt-3">
  <div class="py-3">
    <app-back-arrow></app-back-arrow>
  </div>
  <div
    class="d-flex justify-content-start"
    id="{{ 'container-' + emotionIndex }}"
  >
    <mat-slider #slider [max]="max" [min]="min" [disableRipple]="true">
      <input matSliderThumb />
    </mat-slider>
    <p class="fs-16 mt-2 profile-margin"></p>
  </div>

  <div class="d-flex align-items-center justify-content-center pb-4 mb-2">
    <label class="hoverable pointer" for="fileInput">
      <img
        [src]="
          this.selectedImage
            ? this.selectedImage
            : getProfileImage()
            ? blobStorageLink + getProfileImage()
            : defaultImage
        "
        (error)="handleImageError($event)"
        alt="upload_image"
        class="profile-image-88 rounded-object profile-border"
      />
      <div class="hover-text">
        <span class="material-symbols-outlined white-color">
          photo_camera
        </span>
      </div>
    </label>
    <br />
    <input
      id="fileInput"
      type="file"
      (change)="onSelect($event)"
      accept=".jpg, .jpeg, .png"
    />
  </div>

  <div>
    <form
      [formGroup]="editProfile"
      (ngSubmit)="isImageUpdated ? uploadImage() : update()"
      class="d-flex flex-column w-100"
    >
      <mat-form-field>
        <mat-icon class="grey-disable-color" matPrefix>person_outline</mat-icon>
        <mat-label>Full Name</mat-label>
        <input
          matInput
          placeholder="Full Name"
          formControlName="fullName"
          maxlength="30"
        />
        <mat-error
          *ngIf="this.editProfile.controls['fullName'].hasError('required')"
        >
          Please enter fullname
        </mat-error>
        <mat-error
          *ngIf="this.editProfile.controls['fullName'].hasError('pattern')"
        >
          It should contain only letters
        </mat-error>
        <mat-error
          class="lh-1"
          *ngIf="this.editProfile.controls['fullName'].hasError(' maxlength')"
          >It should contain less than 30 characters
        </mat-error>
      </mat-form-field>
      <mat-form-field
        [ngClass]="{
        'valid': editProfile.controls['userName'].hasError('userCheck')|| this.editProfile.controls['userName'].hasError('previousUser'),
      }"
      >
        <mat-icon matPrefix class="grey-disable-color"
          >account_circle_outline</mat-icon
        >
        <mat-label>User Name</mat-label>
        <input
          matInput
          placeholder="User Name"
          formControlName="userName"
          maxlength="30"
        />

        <mat-hint
          *ngIf="this.editProfile.controls['userName'].hasError('userCheck')"
          [ngClass]="{
            success: editProfile.controls['userName'].hasError('userCheck'),
          }"
        >
          {{ verifyUserNameResponseMessage }}
        </mat-hint>
        <mat-error
          *ngIf="this.editProfile.controls['userName'].hasError('userExists')"
        >
          {{ verifyUserNameResponseMessage }}
        </mat-error>
        <mat-error
          *ngIf="this.editProfile.controls['userName'].hasError('previousUser')"
        >
        </mat-error>
        <mat-error
          *ngIf="
            this.editProfile.controls['userName'].hasError('userCheckFailed')
          "
          >User check failed
        </mat-error>

        <mat-spinner
          *ngIf="userNameLoader"
          matSuffix
          diameter="20"
          class="mx-3"
        ></mat-spinner>
        <mat-error
          *ngIf="this.editProfile.controls['userName'].hasError('required')"
        >
          Please enter username
        </mat-error>
        <mat-error
          class="lh-1"
          *ngIf="
            editProfile.controls['userName'].hasError('minlength') &&
            !(
              editProfile.controls['userName']?.hasError('periodAtStartEnd') ||
              editProfile.controls['userName']?.hasError('successivePeriods') ||
              editProfile.controls['userName']?.hasError('spacesNotAllowed') ||
              editProfile.controls['userName']?.hasError('invalidCharacters')
            )
          "
        >
          Username should be 6 characters long
        </mat-error>
        <mat-error
          class="lh-1"
          *ngIf="
            this.editProfile.controls['userName'].hasError('maxlength') &&
            !(
              editProfile.controls['userName']?.hasError('periodAtStartEnd') ||
              editProfile.controls['userName']?.hasError('successivePeriods') ||
              editProfile.controls['userName']?.hasError('spacesNotAllowed') ||
              editProfile.controls['userName']?.hasError('invalidCharacters')
            )
          "
          >It should contain less than 30 characters
        </mat-error>
        <mat-error
          class="lh-1"
          *ngIf="editProfile.controls['userName']?.hasError('periodAtStartEnd')"
          >Username cannot start or end with a period</mat-error
        >
        <mat-error
          class="lh-1"
          *ngIf="
            editProfile.controls['userName']?.hasError('successivePeriods')
          "
          >Username cannot have successive periods</mat-error
        >
        <mat-error
          class="lh-1"
          *ngIf="editProfile.controls['userName']?.hasError('spacesNotAllowed')"
          >Username cannot contain spaces</mat-error
        >
        <mat-error
          class="lh-1"
          *ngIf="
            editProfile.controls['userName']?.hasError('invalidCharacters') &&
            !editProfile.controls['userName'].hasError('required')
          "
          >Username can only contain letters, numbers, and
          underscores</mat-error
        >
      </mat-form-field>
      <div>
        <mat-label>Gender</mat-label>
        <div class="d-flex align-items-center lato-font-family">
          <mat-radio-group formControlName="genderType">
            <mat-radio-button
              value="MALE"
              ngDefaultControl
              class="lato-font-family"
              >Male</mat-radio-button
            >
            <mat-radio-button value="FEMALE" ngDefaultControl
              >Female</mat-radio-button
            >
            <mat-radio-button value="OTHERS" ngDefaultControl
              >Others</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>
      <mat-form-field>
        <mat-icon matPrefix class="grey-disable-color">mail_outline</mat-icon>
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" />

        <mat-error
          class="m-1 lh-1"
          *ngIf="
            editProfile.controls['email'].hasError('email') &&
            !editProfile.controls['email'].hasError('required')
          "
        >
          Please enter a valid email address
        </mat-error>

        <mat-error *ngIf="editProfile.controls['email'].hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="editProfile.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-icon matPrefix class="grey-disable-color">call</mat-icon>
        <mat-label>Mobile Number</mat-label>
        <input
          matInput
          placeholder="Mobile number"
          formControlName="mobileNumber"
          maxlength="10"
        />
        <mat-error
          *ngIf="editProfile.controls['mobileNumber'].hasError('required')"
        >
          Please enter Mobile Number
        </mat-error>
        <mat-error
          class="m-1 lh-1"
          *ngIf="editProfile.controls['mobileNumber'].hasError('pattern')"
        >
          Please enter a valid Mobilenumber
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Date of Birth</mat-label>
        <input
          [min]="minDate"
          [max]="maxDate"
          formControlName="dateOfBirth"
          (click)="openDatePicker()"
          matInput
          placeholder="DD/MM/YYYY"
          [matDatepicker]="picker"
          readonly="true"
        />
        <mat-datepicker-toggle
          matIconPrefix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
      <div class="d-flex justify-content-center mt-3">
        <button
          mat-raised-button
          type="submit"
          [disabled]="!isFormValid()"
          [ngClass]="{ 'disabled-button': !isFormValid() }"
          class="fw-500 fs-16 btn-primary background-color white-color lato-font-family mt-2 margin px-5"
        >
          Update profile
        </button>
      </div>
    </form>
  </div>
</div>
