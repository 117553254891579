import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GetAppService {
  playstoreUrl =
    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.scremer';
  applestoreUrl = 'https://apps.apple.com/in/app/scremer/id6451445213';

  constructor(private router: Router) {}

  getApp() {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = /(iphone|ipad|ipod)/i.test(navigator.userAgent);
    if (isAndroid) {
      window.open(this.playstoreUrl);
    } else if (isIOS) {
      window.open(this.applestoreUrl);
    }
  }

  downloadApp() {
    this.router.navigate(['/home']);
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = /(iphone|ipad|ipod)/i.test(navigator.userAgent);
    if (isAndroid) {
      window.location.href = this.playstoreUrl;
    } else if (isIOS) {
      window.location.href = this.applestoreUrl;
    } else {
      this.router.navigate(['/sign-up']);
    }
  }
}
